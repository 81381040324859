.billing-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.5fr 1fr 80px;
  border: 2px solid var(--color-tab-light-grey);
  background: #fff;
  padding: 5px;
  padding-left: 15px;
  min-height: 25px;

  span {
    text-align: left;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Demi';
  }

  &__column {
    &--modal {
      position: absolute;
      z-index: 1;
      transform: translateX(600px);
    }
  }
}

.billing-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.5fr 1fr 80px;
  align-items: center;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  background: #fff;
  padding: 5px;
  padding-left: 15px;
  min-height: 25px;

  &:hover {
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    background-color: var(--color-tab-light-grey);
  }
}

.billing-body-content {
  padding-top: 5px;
  p {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }

  &__edit-data {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 50%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }

  &__icon {
    color: var(--color-white);
  }
}

.billing-body-buttons {
  &__send {
    padding-top: 5px;
    padding-bottom: 10px;
  }
}
